
button{
    border: none;
}
.whatsApp {
    position: fixed;
    z-index: 5000;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: 5%;
}

.whatsApp .main {
    width: 90%;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.whatsApp .main .logo {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: green;
}

.whatsApp .main .logo .whatsapp-icon {
    color: white;
    height: 40px;
}
.whatsApp .main .logo-ordernow{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-image: linear-gradient(to top,
    #f09b41 0,
    #e3715e 100%);
}

.whatsApp .main .logo-ordernow .shopping-icon {
    color: white;
    height: 30px;
}



/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
    .whatsApp {
        height: 70px;
        bottom: 7%;
    }
    
    .whatsApp .main {
        width: 90%;
    }
    
    .whatsApp .main .logo {
        width: 70px;
        height: 70px;
    }
    
    .whatsApp .main .logo .whatsapp-icon {
        height: 40px;
    }
    .whatsApp .main .logo-ordernow{
        width: 70px;
        height: 70px;
    }
    
    .whatsApp .main .logo-ordernow .shopping-icon {
        height: 30px;
    }
    

}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    .whatsApp {
        height: 65px;
        bottom: 7%;
    }
    
    .whatsApp .main {
        width: 95%;
    }
    
    .whatsApp .main .logo {
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo .whatsapp-icon {
        height: 40px;
    }
    .whatsApp .main .logo-ordernow{
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo-ordernow .shopping-icon {
        height: 25px;
    }
    
    
  
}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    .whatsApp {
        height: 65px;
        bottom: 8%;
    }
    
    .whatsApp .main {
        width: 95%;
    }
    
    .whatsApp .main .logo {
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo .whatsapp-icon {
        height: 40px;
    }
    .whatsApp .main .logo-ordernow{
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo-ordernow .shopping-icon {
        height: 25px;
    }
    
   

}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 321px) {
    .whatsApp {
        height: 65px;
        bottom: 8%;
    }
    
    .whatsApp .main {
        width: 95%;
    }
    
    .whatsApp .main .logo {
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo .whatsapp-icon {
        height: 40px;
    }
    .whatsApp .main .logo-ordernow{
        width: 65px;
        height: 65px;
    }
    
    .whatsApp .main .logo-ordernow .shopping-icon {
        height: 25px;
    }
    

}