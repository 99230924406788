input {
    border: none;
    box-shadow: 1px 1px 2px #e3715e,
        -1px -1px 2px #f09b41,
        inset -1px -1px 2px #f09b41,
        inset 1px 1px 2px #e3715e;
    margin: 2px 0;
    padding: 5px;
}

.label {
    text-align: start;
}

input {
    border: none;
    box-shadow: 1px 1px 2px #e3715e,
        -1px -1px 2px #f09b41,
        inset -1px -1px 2px #f09b41,
        inset 1px 1px 2px #e3715e;
    margin: 2px 0;
    padding: 5px;
}

.popup {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #ffffff97;
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.popup .main {
    width: 800px;
    height: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 2px 2px 3px #e3715e,
        -1px -1px 2px #f09b41,
        inset -1px -1px 2px #f09b41,
        inset 2px 2px 3px #e3715e;
    position: relative;
}

.popup .main .lamp {
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -10px;
    left: -150px;
    transform: translate(0%, 0%);
}

.popup .main .logo {
    width: 300px;
    height: 500px;
    align-items: center;
    justify-content: start;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.popup .main .logo img {
    width: 230px;
}



.popup .main .form {
    margin-left: 50px;
    width: 500px;
    height: 500px;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.popup .main .form .heading{
    width: 90%;
}
.popup .main .form h1 {
    font-size: 72px;
    font-family: "Berkshire Swash", sans-serif;
    color: #e3715e;
    line-height: 0px;
}

.popup .main .form form {
    width: 100%;
    align-items: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}

.popup .main .form form label {
    width: 83%;
    padding: 5px 0;
}

.popup .main .form form input {
    padding: 8px 20px;
    width: 360px;
}

.popup .main .form form .buttons {
    margin-top: 10px;
    width: 400px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.popup .main .form form .buttons input {
    border-radius: 5px;
    margin: 10px 0;
    padding: 5px 0;
    width: 190px;
    font-size: 18px;
    font-weight: 500;
}

.order-btn {
    background-image: linear-gradient(to top,
            #f09b41 0,
            #e3715e 100%);
    color: white;
}

.order-btn:hover {
    background-image: linear-gradient(to top,
            #ffffff 0,
            #ffffff 100%);
    color: #e3715e;
}

.return-btn {

    background-color: white;
    color: #e3715e;
}

.return-btn:hover {

    background-image: linear-gradient(to top,
            #f09b41 0,
            #e3715e 100%);
    color: white;
}

/* Pop 2 */
/* Pop 2 */
/* Pop 2 */



.popup1 {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff97;
}

.popup1 .main {
    width: 800px;
    height: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 2px 2px 3px #e3715e,
        -1px -1px 2px #f09b41,
        inset -1px -1px 2px #f09b41,
        inset 2px 2px 3px #e3715e;
    position: relative;
}

.popup1 .main .lamp {
    width: 300px;
    height: 300px;
    align-items: start;
    justify-content: center;
    display: flex;
    overflow: hidden;
    margin-top: -160px;
    margin-bottom: 30px;
}

.popup1 .main .lamp img {
    width: 300px;
    height: 300px;
    align-items: start;
    justify-content: center;
    display: flex;
}

.popup1 .main .thanks {
    padding-top: 40px;
    width: 90%;
    height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.popup1 .main .thanks h1 {
    text-align: center;
    font-size: 50px;
    font-family: "Berkshire Swash", sans-serif;
    color: #e3715e;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    padding: 0;
    margin: 0;
    line-height: 40px;
    margin-top: 20px;
}

.popup1 .main .thanks h2 {
    text-align: center;
    font-size: 24px;
    color: #000000;
    margin: 0;
    padding: 0;
    letter-spacing: 0px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    line-height: 24px;
    margin-top: 20px;
    font-weight: 400;
}



.popup1 .main .thanks .return-btn {
    font-size: 18px;
    padding: 5px 20px;
    background-image: linear-gradient(to top,
            #f09b41 0,
            #e3715e 100%);
    color: white;
}

.popup1 .main .thanks .return-btn:hover {
    background-image: linear-gradient(to top,
            #ffffff 0,
            #ffffff 100%);
    color: #e3715e;
}







/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
    input {
        border: none;
        box-shadow: 1px 1px 2px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 1px 1px 2px #e3715e;
        margin: 2px 0;
        padding: 5px;
    }

    .popup {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff97;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .popup .main {
        width: 800px;
        height: 500px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup .main .lamp {
        position: absolute;
        width: 400px;
        height: 400px;
        bottom: -10px;
        left: -150px;
        transform: translate(0%, 0%);
    }

    .popup .main .logo {
        width: 300px;
        height: 500px;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
    }

    .popup .main .logo img {
        width: 230px;
    }



    .popup .main .form {
        margin-left: 50px;
        width: 500px;
        height: 500px;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .popup .main .form h1 {

        font-size: 72px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        line-height: 0px;
    }

    .popup .main .form form {
        width: 100%;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;
    }

    .popup .main .form form label {
        padding: 5px 0;
    }

    .popup .main .form form input {
        padding: 8px 20px;
        width: 360px;
    }

    .popup .main .form form .buttons {
        margin-top: 10px;
        width: 400px;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .popup .main .form form .buttons input {
        border-radius: 5px;
        margin: 10px 0;
        padding: 5px 0;
        width: 190px;
        font-size: 18px;
        font-weight: 500;
    }

    .order-btn {
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .order-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }

    .return-btn {

        background-color: white;
        color: #e3715e;
    }

    .return-btn:hover {

        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    /* Pop 2 */
    /* Pop 2 */
    /* Pop 2 */



    .popup1 {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff97;
    }

    .popup1 .main {
        width: 800px;
        height: 500px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup1 .main .lamp {
        width: 300px;
        height: 300px;
        align-items: start;
        justify-content: center;
        display: flex;
        overflow: hidden;
        margin-top: -160px;
        margin-bottom: 30px;
    }

    .popup1 .main .lamp img {
        width: 300px;
        height: 300px;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .popup1 .main .thanks {
        padding-top: 40px;
        width: 90%;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .popup1 .main .thanks h1 {
        text-align: center;
        font-size: 50px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        margin: 0;
        padding: 0;
        letter-spacing: 2px;
        padding: 0;
        margin: 0;
        line-height: 40px;
        margin-top: 20px;
    }

    .popup1 .main .thanks h2 {
        text-align: center;
        font-size: 24px;
        color: #000000;
        margin: 0;
        padding: 0;
        letter-spacing: 0px;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        line-height: 24px;
        margin-top: 20px;
        font-weight: 400;
    }



    .popup1 .main .thanks .return-btn {
        font-size: 18px;
        padding: 5px 20px;
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .popup1 .main .thanks .return-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }






}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    input {
        border: none;
        box-shadow: 1px 1px 2px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 1px 1px 2px #e3715e;
        margin: 2px 0;
        padding: 5px;
    }

    .popup {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff97;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .popup .main {
        width: 650px;
        height: 500px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
    }

    .popup .main .logo {
        width: 200px;
        height: 500px;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        position: relative;
    }

    .popup .main .logo img {
        width: 180px;
    }

    .popup .main .logo .lamp {
        position: absolute;
        left: -150px;
        bottom: 20px;
        width: 370px;
        height: 370px;
    }

    .popup .main .form {
        margin-left: 10px;
        width: 400px;
        height: 500px;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .popup .main .form h1 {

        font-size: 56px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        line-height: 0px;
    }

    .popup .main .form form {
        width: 100%;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
    }

    .popup .main .form form label {
        padding: 5px 0;
    }

    .popup .main .form form input {
        padding: 8px 10px;
        width: 340px;
    }

    .popup .main .form form .buttons {
        margin-top: 10px;
        width: 360px;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .popup .main .form form .buttons input {
        border-radius: 5px;
        margin: 10px 0;
        padding: 5px 0;
        width: 170px;
        font-size: 16px;
        font-weight: 500;
    }

    .order-btn {
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .order-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }

    .return-btn {

        background-color: white;
        color: #e3715e;
    }

    .return-btn:hover {

        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }


  /* Pop 2 */
    /* Pop 2 */
    /* Pop 2 */



    .popup1 {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff97;
    }

    .popup1 .main {
        width: 680px;
        height: 480px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup1 .main .lamp {
        width: 300px;
        height: 300px;
        align-items: start;
        justify-content: center;
        display: flex;
        overflow: hidden;
        margin-top: -160px;
        margin-bottom: 30px;
    }

    .popup1 .main .lamp img {
        width: 300px;
        height: 300px;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .popup1 .main .thanks {
        padding-top: 40px;
        width: 90%;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .popup1 .main .thanks h1 {
        text-align: center;
        font-size: 42px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        margin: 0;
        padding: 0;
        letter-spacing: 2px;
        padding: 0;
        margin: 0;
        line-height: 40px;
        margin-top: 20px;
    }

    .popup1 .main .thanks h2 {
        text-align: center;
        font-size: 20px;
        color: #000000;
        margin: 0;
        padding: 0;
        letter-spacing: 0px;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        line-height: 24px;
        margin-top: 20px;
        font-weight: 400;
    }



    .popup1 .main .thanks .return-btn {
        font-size: 18px;
        padding: 5px 20px;
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .popup1 .main .thanks .return-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }


}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    input {
        border: none;
        box-shadow: 1px 1px 2px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 1px 1px 2px #e3715e;
        margin: 2px 0;
        padding: 5px;
    }

    .popup {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff97;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .popup .main {
        width: 450px;
        height: 570px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup .main .lamp {
        position: absolute;
        width: 250px;
        height: 250px;
        top: -80px;
        left: -55px;
        transform: translate(0%, 0%);
    }

    .popup .main .logo {
        width: 150px;
        height: 150px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        padding-top: 40px;
        overflow: hidden;
    }

    .popup .main .logo img {
        width: 150px;
    }



    .popup .main .form {
        margin-left: 0px;
        width: 420px;
        height: 350px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }

    .popup .main .form h1 {
        font-size: 42px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        line-height: 0px;
        text-align: center;
        padding: 0;
        margin-top: -20px;
    }

    .popup .main .form form {
        width: 400px;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;
    }

    .popup .main .form form label {
        padding: 5px 10px;
    }

    .popup .main .form form input {
        padding: 8px 15px;
        width: 370px;
    }

    .popup .main .form form .buttons {
        margin-top: 10px;
        width: 400px;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .popup .main .form form .buttons input {
        border-radius: 5px;
        margin: 10px 0;
        padding: 5px 0;
        width: 190px;
        font-size: 18px;
        font-weight: 500;
    }

    .order-btn {
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .order-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }

    .return-btn {

        background-color: white;
        color: #e3715e;
    }

    .return-btn:hover {

        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }


     /* Pop 2 */
    /* Pop 2 */
    /* Pop 2 */



    .popup1 {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff97;
    }

    .popup1 .main {
        width: 85%;
        height: 480px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup1 .main .lamp {
        width: 320px;
        height: 320px;
        align-items: start;
        justify-content: center;
        display: flex;
        overflow: hidden;
        margin-top: -160px;
        margin-bottom: 30px;
    }

    .popup1 .main .lamp img {
        width: 320px;
        height: 320px;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .popup1 .main .thanks {
        padding-top: 36px;
        width: 90%;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .popup1 .main .thanks h1 {
        text-align: center;
        font-size: 36px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        margin: 0;
        padding: 0;
        letter-spacing: 2px;
        padding: 0;
        margin: 0;
        line-height: 40px;
        margin-top: 20px;
    }

    .popup1 .main .thanks h2 {
        text-align: center;
        font-size: 18px;
        color: #000000;
        margin: 0;
        padding: 0;
        letter-spacing: 0px;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        line-height: 24px;
        margin-top: 20px;
        font-weight: 400;
    }



    .popup1 .main .thanks .return-btn {
        font-size: 18px;
        padding: 5px 15px;
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .popup1 .main .thanks .return-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }



}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 321px) {
    input {
        border: none;
        box-shadow: 1px 1px 2px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 1px 1px 2px #e3715e;
        margin: 2px 0;
        padding: 5px;
    }

    .popup {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #ffffff97;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .popup .main {
        width: 90%;
        height: 650px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup .main .lamp {
        position: absolute;
        width: 250px;
        height: 250px;
        top: 32px;
        left: -60px;
        transform: translate(0%, 0%);
    }

    .popup .main .logo {
        width: 150px;
        height: 150px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        padding-top: 40px;
        overflow: hidden;
    }

    .popup .main .logo img {
        width: 150px;
    }



    .popup .main .form {
        margin-top: 60px;
        margin-left: 0px;
        width: 100%;
        height: 350px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: 60px;
    }

    .popup .main .form h1 {
        font-size: 42px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        line-height: 0px;
        text-align: center;
        padding: 0;
        margin-top: -20px;
    }

    .popup .main .form form {
        padding: 10px auto;
        width: 90%;
        align-items: start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
    }

    .popup .main .form form label {
        padding: 5px 10px;
        text-align: start;
    }

    .popup .main .form form input {
        padding: 8px 0px;
        padding-left: 10px;
        width: calc(100% - 10px);
    }

    .popup .main .form form .buttons {
        margin-top: 10px;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        display: flex;
    }

    .popup .main .form form .buttons input {
        border-radius: 5px;
        margin: 10px 0;
        padding: 5px 0;
        width: 150px;
        font-size: 16px;
        font-weight: 400;
    }

    .order-btn {
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .order-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }

    .return-btn {

        background-color: white;
        color: #e3715e;
    }

    .return-btn:hover {
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

        /* Pop 2 */
    /* Pop 2 */
    /* Pop 2 */



    .popup1 {
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 10000;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff97;
    }

    .popup1 .main {
        width: 85%;
        height: 480px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 2px 2px 3px #e3715e,
            -1px -1px 2px #f09b41,
            inset -1px -1px 2px #f09b41,
            inset 2px 2px 3px #e3715e;
        position: relative;
    }

    .popup1 .main .lamp {
        width: 260px;
        height: 260px;
        align-items: start;
        justify-content: center;
        display: flex;
        overflow: hidden;
        margin-top: -160px;
        margin-bottom: 30px;
    }

    .popup1 .main .lamp img {
        width: 260px;
        height: 260px;
        align-items: start;
        justify-content: center;
        display: flex;
    }

    .popup1 .main .thanks {
        padding-top: 36px;
        width: 95%;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .popup1 .main .thanks h1 {
        text-align: center;
        font-size: 30px;
        font-family: "Berkshire Swash", sans-serif;
        color: #e3715e;
        margin: 0;
        padding: 0;
        letter-spacing: 2px;
        padding: 0;
        margin: 0;
        line-height: 40px;
        margin-top: 20px;
    }

    .popup1 .main .thanks h2 {
        text-align: center;
        font-size: 16px;
        color: #000000;
        margin: 0;
        padding: 0;
        letter-spacing: 0px;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        line-height: 24px;
        margin-top: 20px;
        font-weight: 400;
    }



    .popup1 .main .thanks .return-btn {
        font-size: 16px;
        padding: 5px 10px;
        background-image: linear-gradient(to top,
                #f09b41 0,
                #e3715e 100%);
        color: white;
    }

    .popup1 .main .thanks .return-btn:hover {
        background-image: linear-gradient(to top,
                #ffffff 0,
                #ffffff 100%);
        color: #e3715e;
    }

}

