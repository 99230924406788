

.parallax-1 {
   background-color: white ;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
   
 
}

.parallax-2 {
    background-image: linear-gradient(to top,
    #d9c4b5 0,
    #eadad0 7%,
    #B7A294 28%,
    #B5A092 40%,
    #B7A294 60%,
    #eadad0 78%,
    #B7A294 85%,
    #d9c4b5 100%);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 1350px;
}

.parallax-3 {
    background: url("../assets/bg-3.png");
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 960px;
 
}


/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
   
    .parallax-2 {
        height: 1370px;
    }
    
    .parallax-3 {
        height: 960px;
     
    }

}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    .parallax-2 {
        height: 1300px;
    }
    
    .parallax-3 {
        height: 1150px;
     
    }

}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    .parallax-2 {
        height: 1920px;
    }
    
    .parallax-3 {
        height: 1750px;
     
    }

}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 260px) {
    .parallax-2 {
        height: 1750px;
    }
    
    .parallax-3 {
        height: 920px;
     
    }

}
