.feature {
    width: 100%;
    height: 800px;
    align-items: center;
    justify-content: end;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-top: 230px;
    position: relative;
    overflow: hidden;
}


.feature .main {
    width: 100%;
    max-width: 1200px;
    height: 100px;
    align-items: start;
    justify-content: start;
    display: flex;
    position: relative;
}

.feature .main .heading {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
}

.feature .main .heading h1 {
    text-align: center;
    font-size: 72px;
    font-family: "Berkshire Swash", sans-serif;
    color: #611d1d;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    line-height: 60px;
}

.feature .dis {
    width: 100%;
    max-width: 1200px;
    height: 540px;
    align-items: center;
    justify-content: end;
    display: flex;
    position: relative;
    margin-bottom: 20px;
}

.feature .dis .list-items {
    width: 880px;
    height: 540px;
    align-items: center;
    justify-content: end;
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.feature .dis .list-items .item {
    width: 200px;
    height: 250px;
    background-color: #ffffff;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.feature .dis .list-items .item .image-part {
    width: 185px;
    height: 185px;
    background-color: #ffffff;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    align-items: center;
    justify-content: center;
    display: flex;
}

.feature .dis .list-items .item .image-part img {
    width: 185px;
    height: 185px;
}

.feature .dis .list-items .item .text {
    width: 200px;
    height: 50px;
}







.feature .border {
    width: 100%;
    height: 100px;
    background-color: #611d1d;
    position: relative;
}

.feature .border .image {
    position: absolute;
    width: 600px;
    height: 600px;
    align-items: end;
    justify-content: center;
    display: flex;
    bottom: 50px;
}

.feature .border img {
    height: 700px;
    align-items: center;
    justify-content: center;
    display: flex;
}

h3 {
    font-size: 22px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #611d1d;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    line-height: 24px;
}


/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
    .feature {
        width: 100%;
        height: 900px;
        align-items: center;
        justify-content: end;
        display: flex;
        flex-direction: column;
        padding-top: 230px;
    }


    .feature .main {
        width: 100%;
        max-width: 960px;
        height: 70px;
        align-items: start;
        justify-content: start;
        display: flex;
        position: relative;
    }

    .feature .main .heading {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .feature .main .heading h1 {
        text-align: center;
        font-size: 65px;
        line-height: 35px;
    }

    .feature .dis {
        width: 100%;
        max-width: 960px;
        height: 840px;
        align-items: start;
        justify-content: end;
        display: flex;
        position: relative;
        margin-bottom: 20px;
    }

    .feature .dis .list-items {
        width: 880px;
        height: 540px;
        align-items: center;
        justify-content: end;
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    .feature .dis .list-items .item {
        width: 200px;
        height: 250px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 10px;
    }

    .feature .dis .list-items .item .image-part {
        width: 185px;
        height: 185px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .feature .dis .list-items .item .image-part img {
        width: 185px;
        height: 185px;
    }

    .feature .dis .list-items .item .text {
        width: 200px;
        height: 50px;
    }







    .feature .border {
        width: 100%;
        height: 100px;
        background-color: #611d1d;
        position: relative;
    }

    .feature .border .image {
        position: absolute;
        width: 400px;
        height: 600px;
        align-items: end;
        justify-content: center;
        display: flex;
        bottom: 50px;
    }

    .feature .border img {
        height: 500px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    h3 {
        font-size: 20px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #611d1d;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
        line-height: 22px;
    }


}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    .feature {
        width: 100%;
        height: 970px;
        align-items: center;
        justify-content: end;
        display: flex;
        flex-direction: column;
        padding-top: 200px;
    }


    .feature .main {
        width: 100%;
        height: 50px;
        align-items: start;
        justify-content: start;
        display: flex;
        position: relative;
    }

    .feature .main .heading {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .feature .main .heading h1 {
        text-align: center;
        font-size: 56px;
        line-height: 35px;
    }

    .feature .dis {
        width: 90%;
        min-width: 760px;
        height: 840px;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
        margin-bottom: 20px;
    }

    .feature .dis .list-items {
        width: 100%;
        height: 500px;
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    .feature .dis .list-items .item {
        width: 165px;
        height: 215px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 5px;
    }

    .feature .dis .list-items .item .image-part {
        width: 150px;
        height: 150px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .feature .dis .list-items .item .image-part img {
        width: 150px;
        height: 150px;
    }

    .feature .dis .list-items .item .text {
        width: 150px;
        height: 45px;
    }

    .feature .border {
        width: 100%;
        height: 100px;
        background-color: #611d1d;
        position: relative;
    }

    .feature .border .image {
        position: absolute;
        width: 380px;
        height: 500px;
        align-items: end;
        justify-content: center;
        display: flex;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    .feature .border img {
        height: 450px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    h3 {
        font-size: 18px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #611d1d;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
        line-height: 22px;
    }

}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    .feature {
        width: 100%;
        height: 1600px;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        padding-top: 150px;
    }


    .feature .main {
        width: 100%;
        height: 50px;
        align-items: start;
        justify-content: start;
        display: flex;
        position: relative;
    }

    .feature .main .heading {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .feature .main .heading h1 {
        text-align: center;
        font-size: 36px;
        line-height: 20px;
    }

    .feature .dis {
        width: 80%;
        min-width: 480px;
        height: 1550px;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
        margin-bottom: 20px;
    }

    .feature .dis .list-items {
        width: 100%;
        height: 1100px;
        align-items: center;
        justify-content:space-around;
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    .feature .dis .list-items .item {
        width: 210px;
        height: 250px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 5px;
    }

    .feature .dis .list-items .item .image-part {
        width: 190px;
        height: 190px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .feature .dis .list-items .item .image-part img {
        width: 190px;
        height: 190px;
    }

    .feature .dis .list-items .item .text {
        width: 150px;
        height: 45px;
    }

    .feature .border {
        width: 100%;
        height: 100px;
        background-color: #611d1d;
        position: relative;
    }

    .feature .border .image {
        position: absolute;
        width: 380px;
        height: 500px;
        align-items: end;
        justify-content: center;
        display: flex;
        bottom: 50px;
        left: 10%;
        transform: translateX(-10%);
    }

    .feature .border img {
        height: 450px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    h3 {
        font-size: 16px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #611d1d;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 0;
        margin: 0;
        margin-top: 5px;
        line-height: 22px;
    }

}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 260px) {
    .feature {
        width: 100%;
        height: 1350px;
        align-items: center;
        justify-content: start;
        display: flex;
        flex-direction: column;
        padding-top: 220px;
    }


    .feature .main {
        width: 100%;
        height: 50px;
        align-items: start;
        justify-content: start;
        display: flex;
        position: relative;
    }

    .feature .main .heading {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 50px;
    }

    .feature .main .heading h1 {
        text-align: center;
        font-size: 26px;
        line-height: 20px;
    }

    .feature .dis {
        width: 90%;
        min-width: 320px;
        height: 1550px;
        align-items: start;
        justify-content: center;
        display: flex;
        position: relative;
        margin-bottom: 20px;
    }

    .feature .dis .list-items {
        width: 100%;
        height: 850px;
        align-items: center;
        justify-content:space-around;
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    .feature .dis .list-items .item {
        width: 150px;
        height: 190px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 5px 0;
    }

    .feature .dis .list-items .item .image-part {
        width: 135px;
        height: 135px;
        background-color: #ffffff;
        box-shadow: 4px 4px 6px #0d275045,
            -3px -3px 5px #FFFFFF,
            inset -2px -2px 3px #FFFFFF,
            inset 3px 3px 6px #0d275045;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .feature .dis .list-items .item .image-part img {
        width: 140px;
        height: 140px;
    }

    .feature .dis .list-items .item .text {
        width: 140px;
        height: 35px;
    }

    .feature .border {
        width: 100%;
        height: 100px;
        background-color: #611d1d;
        position: relative;
    }

    .feature .border .image {
        position: absolute;
        width: 380px;
        height: 500px;
        align-items: end;
        justify-content: center;
        display: flex;
        bottom: 50px;
        left: 10%;
        transform: translateX(-10%);
    }

    .feature .border img {
        height: 450px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    h3 {
        font-size: 14px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #611d1d;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 0;
        margin: 0;
        margin-top: 10px;
        line-height: 14px;
    }

}