.header {
    width: 100%;
    height: 90px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: fixed;
    z-index: 10000;
    background-color: #fffffff3;
    border-bottom: 2px solid #611d1d;
    box-shadow: 3px 3px 5px #611d1d;
}

.header .main {
    width: 100%;
    max-width: 1200px;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.header .main .logo {
    height: 90px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.header .main .logo img {
    height: 80px;
}

.language{
    width: 110px;
    align-items: center;
    justify-content: space-between;
    display: flex;

}
.language button{
   width: 50px;
font-size: large;
    align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  border: 1px solid #611d1d;
  box-shadow: 1px 1px 2px #611d1d;

}
.language button:hover{
    width: 40px;
 background-color: #611d1d;
     align-items: center;
   justify-content: center;
   display: flex;
   border-radius: 5px;
   border: 1px solid #611d1d;
   color: white;
 
 }








@media (max-width: 1240px) and (min-width: 1024px) {
    .header {
        height: 85px;
        border-bottom: 2px solid #611d1d;
        box-shadow: 2px 2px 4px #611d1d;
    }

    .header .main {
        width: 90%;
        max-width: 1150px;
        height: 85px;
    }

    .header .main .logo {
        height: 85px;
    }

    .header .main .logo img {
        height: 75px;
    }



   

}

@media (max-width: 1024px) and (min-width: 768px) {
    .header {
        height: 80px;
        border-bottom: 2px solid #611d1d;
        box-shadow: 2px 2px 4px #611d1d;
    }

    .header .main {
        width: 90%;
        max-width: 950px;
        height: 80px;
    }

    .header .main .logo {
        height: 80px;
    }

    .header .main .logo img {
        height: 70px;
    }


}

@media (max-width: 768px) and (min-width: 480px) {
    .header {
        height: 70px;
        border-bottom: 2px solid #611d1d;
        box-shadow: 2px 2px 4px #611d1d;
    }

    .header .main {
        width: 90%;
        max-width: 720px;
        height: 70px;
    }

    .header .main .logo {
        height: 70px;
    }

    .header .main .logo img {
        height: 62px;
    }

}

@media (max-width: 480px) and (min-width: 320px) {
    .header {
        height: 70px;
        border-bottom: 2px solid #611d1d;
        box-shadow: 2px 2px 4px #611d1d;
    }

    .header .main {
        width: 85%;
        max-width: 440px;
        height: 70px;
    }

    .header .main .logo {
        height: 70px;
    }

    .header .main .logo img {
        height: 62px;
    }
}