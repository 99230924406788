.footer {
    width: 100%;
    margin-top: 70px;
    height: 250px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: url("../assets/bg-3.png");
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
}

.footer .main {
    width: 75%;
    max-width: 1200px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.footer .main .logo {
    width: 180px;
    height: 180px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.footer .main .logo img {
    width: 160px;
    height: 160px;
}

.footer .main .socil-media {
    align-items: center;
    justify-content: space-between;
    display: flex
}

.footer .main .socil-media .icons {
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    margin: 10px;
    background-color: #ffffff;
    border: 2px solid #611d1d;
}

.footer .main .socil-media .icons .icon {
    width: 30px;
    height: 30px;
    color: #611d1d;
}

.footer .main .socil-media .icons:hover {
    color: #ffffff;
    background-color: #611d1d;
}

.footer .main .socil-media .icons:hover .icon {
    color: #ffffff;
}

/* Footer Bottom */
/* Footer Bottom */
/* Footer Bottom */

.footer-bottom {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 15px 0;
    background-color: #611d1d;
}

.footer-bottom .main {
    width: 90%;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    display: flex;
}


.footer-bottom .main p {
    width: 100%;
    color: white;
    font-size: 16px;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer-bottom .main p.arb {
    font-size: 18px;
    text-align: right;
}







@media (max-width: 1240px) and (min-width: 1024px) {
    .footer {
        height: 250px;
        margin-top: 170px;
    }

    .footer .main {
        width: 70%;
        max-width: 1180px;
    }

    .footer .main .logo {
        width: 180px;
        height: 180px;
    }

    .footer .main .logo img {
        width: 170px;
        height: 170px;
    }

    .footer .main .socil-media .icons {
        width: 60px;
        height: 60px;
    }

    .footer .main .socil-media .icons .icon {
        width: 30px;
        height: 30px;
    }

    /* Footer Bottom */
    /* Footer Bottom */
    /* Footer Bottom */

    .footer-bottom {
        padding: 10px 0;
    }

    .footer-bottom .main {
        width: 70%;
    }


    .footer-bottom .main p {
        font-size: 16px;
    }

    .footer-bottom .main p.arb {
        font-size: 18px;
    }

}

@media (max-width: 1024px) and (min-width: 768px) {
    .footer {
        height: 250px;
        margin-top: 0px;
    }

    .footer .main {
        width: 80%;
        max-width: 980px;
    }

    .footer .main .logo {
        width: 150px;
        height: 150px;
    }

    .footer .main .logo img {
        width: 140px;
        height: 140px;
    }

    .footer .main .socil-media .icons {
        width: 50px;
        height: 50px;
    }

    .footer .main .socil-media .icons .icon {
        width: 25px;
        height: 25px;
    }

    /* Footer Bottom */
    /* Footer Bottom */
    /* Footer Bottom */

    .footer-bottom {
        padding: 10px 0;
    }

    .footer-bottom .main {
        width: 70%;
    }


    .footer-bottom .main p {
        font-size: 15px;
    }

    .footer-bottom .main p.arb {
        font-size: 17px;
    }

}

@media (max-width: 768px) and (min-width: 480px) {
    .footer {
        height: 350px;
        margin-top: 0px;
    }

    .footer .main {
        width: 90%;
        max-width: 760px;
        flex-direction: column-reverse;
    }

    .footer .main .logo {
        width: 180px;
        height: 180px;
        margin-top: 10px;
    }

    .footer .main .logo img {
        width: 160px;
        height: 160px;
    }

    .footer .main .socil-media .icons {
        width: 50px;
        height: 50px;
    }

    .footer .main .socil-media .icons .icon {
        width: 30px;
        height: 30px;
    }

    /* Footer Bottom */
    /* Footer Bottom */
    /* Footer Bottom */

    .footer-bottom {
        padding: 10px 0;
    }

    .footer-bottom .main {
        width: 70%;
    }


    .footer-bottom .main p {
        font-size: 15px;
    }

    .footer-bottom .main p.arb {
        font-size: 16px;
    }
}

@media (max-width: 480px) and (min-width: 320px) {
    .footer {
        height: 330px;
        margin-top: 640px;
    }

    .footer .main {
        width: 90%;
        max-width: 450px;
        flex-direction: column-reverse;
    }

    .footer .main .logo {
        width: 160px;
        height: 160px;
    }

    .footer .main .logo img {
        width: 140px;
        height: 140px;
        margin-top: 20px;
    }

    .footer .main .logo img {
        width: 160px;
        height: 160px;
    }

    .footer .main .socil-media .icons {
        margin: 5px;
        width: 40px;
        height: 40px;
    }

    .footer .main .socil-media .icons .icon {

        width: 20px;
        height: 20px;
    }

    /* Footer Bottom */
    /* Footer Bottom */
    /* Footer Bottom */

    .footer-bottom {
        padding: 10px 0;
    }

    .footer-bottom .main {
        width: 90%;
    }


    .footer-bottom .main p {
        font-size: 12px;
    }

    .footer-bottom .main p.arb {
        font-size: 12px;
    }
}