.main-content {
    width: 100%;
    height: 480px;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
}

.main-content .dis {
    width: 100%;
    max-width: 1200px;
    height: 450px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.main-content .dis h1 {
    text-align: center;
    font-size: 72px;
    font-family: "Berkshire Swash", sans-serif;
    color: #611d1d;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    line-height: 60px;
    margin-top: 20px;
}

.main-content .dis h1>span {
    text-align: center;
    font-size: 52px;
    font-family: "Poppins", sans-serif;
    color: #611d1d;
    font-weight: 700;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    line-height: 50px;
}

.main-content .dis .itms-list {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;


}

.main-content .dis .itms-list .item {
    width: 140px;
    height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 30px 40px;
    margin-bottom: 20px;
}

.main-content .dis .itms-list .item .logo {
    width: 130px;
    height: 130px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    background-color: #ffffffc2;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    margin: 10px 0;
}

.main-content .dis .itms-list .item .logo img {
    width: 90px;
}

.main-content .dis .itms-list .item h3 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #611d1d;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
}


/* QURAN */
/* QURAN */
/* QURAN */



.quaran {
    width: 100%;
    height: 1100px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    overflow: hidden;
    position: relative;

}

.quaran .top-clouds {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    z-index: 1000;

}

.quaran .top-clouds img {
    width: 100%;
    height: 120px;
}


.quaran .top {
    width: 100%;
    max-width: 1200px;
    height: 200px;
    align-items: top;
    justify-content: space-between;
    display: flex;
    position: relative;

}

.quaran .top .leaf {
    height: 400px;
    overflow: hidden;
    z-index: 100;

}

.quaran .top .lamb {
    width: 300px;
    height: 300px;
    overflow: hidden;
    z-index: 100;

}

.quaran .top .lamb img {
    height: 300px;

}

.quaran .top .leaf img {
    height: 300px;

}

.quaran .main {
    width: 100%;
    max-width: 1300px;
    height: 550px;
    align-items: center;
    justify-content: start;
    display: flex;
    position: relative;
}

.quaran .main .dis {
    width: 80%;
    height: 600px;
    align-items: start;
    justify-content: center;
    display: flex;
    overflow: hidden;
    margin-top: 20px;
}




.quaran .main .dis .details {
    width: 80%;
    align-items: start;
    justify-content: space-between;
    display: flex;

}



.quaran .main .dis ul>li {

    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #611d1d;
    margin: 0;
    padding: 0;
    text-align: left;
    letter-spacing: 0px;
    z-index: 1100;
}

.quaran .main .stage {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 700px;
    height: 500px;
    align-items: end;
    justify-content: center;
    display: flex;
    overflow: hidden;
}

.quaran .main .stage img {
    height: 500px;
}

.quaran .border {
    width: 100%;
    height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.quaran .border img {
    height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
}




/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
    .main-content {
        width: 100%;
        height: 480px;
    }

    .main-content .dis {
        width: 100%;
        max-width: 940px;
        height: 450px;
    }

    .main-content .dis h1 {
        font-size: 65px;
        line-height: 55px;
        margin-top: 15px;
    }

    .main-content .dis h1>span {
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 45px;
    }

    .main-content .dis .itms-list {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;


    }

    .main-content .dis .itms-list .item {
        width: 150px;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 30px 40px;
        margin-bottom: 20px;
    }

    .main-content .dis .itms-list .item .logo {
        width: 130px;
        height: 130px;
    }

    .main-content .dis .itms-list .item .logo img {
        width: 90px;
    }

    .main-content .dis .itms-list .item h3 {
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #611d1d;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 0;
        margin: 0;
    }


    /* QURAN */
    /* QURAN */
    /* QURAN */



    .quaran {
        width: 100%;
        height: 1100px;

    }

    .quaran .top-clouds {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100px;
        z-index: 1000;

    }

    .quaran .top-clouds img {
        width: 100%;
        height: 110px;
    }


    .quaran .top {
        width: 100%;
        max-width: 960px;
        height: 200px;

    }

    .quaran .top .leaf {
        height: 300px;
        overflow: hidden;

    }

    .quaran .top .lamb {
        width: 300px;
        height: 260px;

    }

    .quaran .top .lamb img {
        height: 250px;

    }

    .quaran .top .leaf img {
        height: 250px;

    }

    .quaran .main {
        width: 100%;
        max-width: 960px;
        height: 500px;
        margin-top: 0;
    }

    /**/
    /**/
    /**/

    .quaran .main .dis {
        width: 80%;
        height: 550px;

    }

    .quaran .main .dis .details {
        width: 100%;
        align-items: start;
        justify-content: start;
        display: flex;

    }

    .quaran .main .dis ul>li {

        font-size: 20px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: left;
        z-index: 1100;
    }

    /**/
    /**/
    /**/


    .quaran .main .stage {
        position: absolute;
        bottom: -50px;
        right: 0;
        width: 520px;
        height: 450px;
    }

    .quaran .main .stage img {
        height: 450px;
    }

    .quaran .border {
        width: 100%;
        height: 300px;
    }

    .quaran .border img {
        height: 300px;
    }


}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    .main-content {
        width: 100%;
        height: 420px;
    }

    .main-content .dis {
        width: 100%;
        max-width: 720px;
        height: 380px;
    }

    .main-content .dis h1 {
        font-size: 56px;
        line-height: 55px;
        margin-top: 15px;
    }

    .main-content .dis h1>span {
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 45px;
    }

    .main-content .dis .itms-list {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;


    }

    .main-content .dis .itms-list .item {
        width: 130px;
        height: 210px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 15px;
        margin-bottom: 20px;
    }

    .main-content .dis .itms-list .item .logo {
        width: 110px;
        height: 110px;
        margin: 10px 0;
    }

    .main-content .dis .itms-list .item .logo img {
        width: 80px;
    }

    .main-content .dis .itms-list .item h3 {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    /* QURAN */
    /* QURAN */
    /* QURAN */



    .quaran {
        width: 100%;
        height: 1020px;

    }

    .quaran .top-clouds {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 90px;
        z-index: 1000;

    }

    .quaran .top-clouds img {
        width: 100%;
        height: 90px;
    }


    .quaran .top {
        width: 90%;
        min-width: 740px;
        height: 150px;

    }

    .quaran .top .leaf {
        height: 250px;
        overflow: hidden;

    }

    .quaran .top .lamb {
        width: 220px;
        height: 230px;

    }

    .quaran .top .lamb img {
        height: 250px;

    }

    .quaran .top .leaf img {
        height: 210px;

    }

    .quaran .main {
        width: 90%;
        min-width: 740px;
        height: 580px;
        margin-top: 30px;
    }

    /**/
    /**/
    /**/

    .quaran .main .dis {
        width: 100%;
        height: 540px;
        margin-top: 0;
        justify-content: start;
    }

    .quaran .main .dis .details {
        width: 80%;
        align-items: start;
        justify-content: start;
        display: flex;

    }

    .quaran .main .dis ul>li {

        font-size: 18px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: left;
        z-index: 1100;
    }

    /**/
    /**/
    /**/

    .quaran .main .stage {
        position: absolute;
        bottom: -50px;
        right: 0;
        width: 380px;
        height: 430px;
    }

    .quaran .main .stage img {
        height: 350px;
    }

    .quaran .border {
        width: 100%;
        height: 250px;
    }

    .quaran .border img {
        height: 250px;
    }


}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    .main-content {
        width: 100%;
        height: 680px;
    }

    .main-content .dis {
        width: 100%;
        max-width: 500px;
        height: 600px;
    }

    .main-content .dis h1 {
        font-size: 36px;
        line-height: 35px;
        margin-top: 20px;
    }

    .main-content .dis h1>span {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 45px;
    }

    .main-content .dis .itms-list {
        width: 100%;
        height: 480px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .main-content .dis .itms-list .item {
        width: 160px;
        height: 210px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 10px;
        margin-bottom: 20px;
    }

    .main-content .dis .itms-list .item .logo {
        width: 140px;
        height: 140px;
        margin: 10px 0;
    }

    .main-content .dis .itms-list .item .logo img {
        width: 90px;
    }

    .main-content .dis .itms-list .item h3 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
    }


    /* QURAN */
    /* QURAN */
    /* QURAN */



    .quaran {
        width: 100%;
        height: 1350px;

    }

    .quaran .top-clouds {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 80px;
        z-index: 1000;

    }

    .quaran .top-clouds img {
        width: 100%;
        height: 80px;
    }


    .quaran .top {
        width: 90%;
        height: 150px;

    }

    .quaran .top .leaf {
        height: 200px;
        overflow: hidden;
    }

    .quaran .top .lamb {
        width: 200px;
        height: 210px;
    }

    .quaran .top .lamb img {
        height: 230px;

    }

    .quaran .top .leaf img {
        height: 180px;

    }

    .quaran .main {
        width: 80%;
        height: 950px;
    }

    /**/
    /**/
    /**/

    .quaran .main .dis {
        margin-top: 100px;
        width: 100%;
        height: 900px;

    }

    .quaran .main .dis .details {
        width: 85%;
        align-items: end;
        justify-content: start;
        display: flex;
        flex-direction: column-reverse;

    }

    .quaran .main .dis ul {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        z-index: 1100;
        padding: 0;
        margin: 0;
    }

    .quaran .main .dis ul>li {

        font-size: 18px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: left;
        z-index: 1100;
    }

    /**/
    /**/
    /**/

    .quaran .main .stage {
        position: absolute;
        bottom: -50px;
        right: 0;
        width: 380px;
        height: 430px;
    }

    .quaran .main .stage img {
        height: 350px;
    }

    .quaran .border {
        width: 100%;
        height: 220px;
    }

    .quaran .border img {
        height: 220px;
    }

}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 321px) {
    .main-content {
        width: 100%;
        height: 650px;
    }

    .main-content .dis {
        min-width: 320px;
        height: 650px;
    }

    .main-content .dis h1 {
        font-size: 26px;
        line-height: 30px;
        margin-top: 10px;
    }

    .main-content .dis h1>span {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 40px;
    }

    .main-content .dis .itms-list {
        margin-top: 15px;
        width: 95%;
        height: 430px;
        align-items: center;
        justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
    }

    .main-content .dis .itms-list .item {
        width: 140px;
        height: 210px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 5px 00px;
        margin-bottom: 20px;
    }

    .main-content .dis .itms-list .item .logo {
        width: 130px;
        height: 130px;
        margin: 10px 0;
    }

    .main-content .dis .itms-list .item .logo img {
        width: 100px;
    }

    .main-content .dis .itms-list .item h3 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    /* QURAN */
    /* QURAN */
    /* QURAN */



    .quaran {
        width: 100%;
        height: 1300px;

    }

    .quaran .top-clouds {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 80px;
        z-index: 1000;

    }

    .quaran .top-clouds img {
        width: 100%;
        height: 80px;
    }


    .quaran .top {
        width: 100%;
        height: 150px;

    }

    .quaran .top .leaf {
        height: 180px;
        overflow: hidden;
    }

    .quaran .top .lamb {
        width: 170px;
        height: 210px;
    }

    .quaran .top .lamb img {
        height: 200px;

    }

    .quaran .top .leaf img {
        height: 170px;

    }

    .quaran .main {
        width: 95%;
        height: 870px;
    }

    /**/
    /**/
    /**/

    .quaran .main .dis {
        margin-top: 100px;
        width: 95%;
        height: 780px;

    }

    .quaran .main .dis .details {
        width: 85%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column-reverse;

    }

    .quaran .main .dis ul {

        width: 100%;
        font-size: 18px;
        font-weight: 400;
        z-index: 1100;
        padding: 0;
        margin: 0;
        margin-left: 5%;
    }

    .quaran .main .dis ul>li {

        font-size: 16px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: left;
        z-index: 1100;
    }

    /**/
    /**/
    /**/
    .quaran .main .stage {
        position: absolute;
        bottom: -50px;
        right: -40px;
        width: 355px;
        height: 400px;
    }

    .quaran .main .stage img {
        height: 270px;
    }

    .quaran .border {
        width: 100%;
        height: 250px;
    }

    .quaran .border img {
        height: 250px;
    }

}