*{
    font-family: "Poppins",sans-serif;
    cursor: pointer;
    
  
}
ul{
    list-style: none;

}

