:root {

    --item1-transform: translateX(-100%) translateY(-5%) scale(1.5);
    --item1-filter: blur(30px);
    --item1-zIndex: 11;
    --item1-opacity: 1;

    --item2-transform: translateX(0);
    --item2-filter: blur(0px);
    --item2-zIndex: 10;
    --item2-opacity: 1;

    --item3-transform: translate(60%, 10%) scale(0.7);
    --item3-filter: blur(30px);
    --item3-zIndex: 9;
    --item3-opacity: 0.1;

    --item4-transform: translate(90%, 20%) scale(0.4);
    --item4-filter: blur(30px);
    --item4-zIndex: 8;
    --item4-opacity: 0;

}

/* carousel */


.carousel {
    width: 100%;
    position: relative;
    height: 100vh;
    align-items: end;
    justify-content: center;
    display: flex;
    background: radial-gradient(circle 400px, #24C5DA, #00002C);
    overflow: hidden;
}


/* Price */
/* Price */
/* Price */

.carousel .price {
    width: 80%;
    height: 390px;
    z-index: 1000;
    align-items: center;
    justify-content: end;
    display: flex;
}

.carousel .price .top-border {
    width: 410px;
    height: 225px;
    border-radius: 13px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-image: linear-gradient(to right,
            #935b07 0,
            #cb9b51 20%,
            #f6e27a 35%,
            #f6f2c0 50%,
            #f6e27a 65%,
            #cb9b51 80%,
            #935b07 100%);

}

.carousel .price .top-inner {
    width: 405px;
    height: 220px;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: radial-gradient(circle 150px, #24C5DA, #00002C);

}

.carousel .price .border {
    width: 395px;
    height: 210px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-image: linear-gradient(to right,
            #935b07 0,
            #cb9b51 20%,
            #f6e27a 35%,
            #f6f2c0 50%,
            #f6e27a 65%,
            #cb9b51 80%,
            #935b07 100%);

}

.carousel .price .border .main {
    width: 390px;
    height: 205px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background-color: #087f8f;
}

.carousel .price .main h3 {
    width: 90%;
    text-align: center;
    font-size: 40px;
    font-family: "Berkshire Swash", sans-serif;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
}

.carousel .price .main h5 {
    width: 90%;
    text-align: right;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    letter-spacing: 1px;
    text-decoration: line-through;

}

.carousel .price .main h1 {
    width: 90%;
    text-align: left;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    margin-top: -40px;


}

.carousel .price .main h2 {
    width: 85%;
    text-align: left;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    margin-top: -5px;
}

/* Price */
/* Price */
/* Price */


.carousel .list {
    position: absolute;
    width: 1140px;
    max-width: 90%;
    height: 700px;
    left: 50%;
    transform: translateX(-50%);
}

.carousel .bg-frame {
    position: absolute;
    width: 1140px;
    height: 700px;
    left: 50%;
    transform: translateX(-50%);
    align-items: end;
    justify-content: start;
    display: flex;
}

.carousel .bg-frame img {
    height: 600px;
}

/* Stage  */
/* Stage  */
/* Stage  */

.carousel .bg-stage {
    position: absolute;
    width: 750px;
    height: 150px;
    left: 50px;
    bottom: 0;
    align-items: end;
    justify-content: start;
    display: flex;
}

.carousel .bg-stage img {
    height: 100%;
}

/* Items */
/* Items */
/* Items */


.carousel .list .item {
    position: absolute;
    left: 0%;
    width: 100%;
    height: 100%;
    font-size: 15px;
    transition: left 0.5s, opacity 0.5s, width 0.5s;
}

.carousel .list .item:nth-child(n + 4) {
    opacity: 0;
}

.carousel .list .item:nth-child(2) {
    z-index: 10;
    transform: translateX(0);
}

.carousel .list .item img {
    height: 550px;
    position: absolute;
    left: 150px;
    top: 50%;
    transform: translateY(-50%);
    transition: right 1.5s;
}

.carousel .list .item .introduce {
    color: white;
    opacity: 0;
    pointer-events: none;
}

/* Discriptions */
/* Discriptions */
/* Discriptions */


.carousel .list .item:nth-child(2) .introduce {
    opacity: 1;
    pointer-events: auto;
    width: 600px;
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%);
    transition: opacity 0.5s;
}

.carousel .list .item .introduce .topic {
    text-align: start;
    font-size: 80px;
    font-family: "Berkshire Swash", sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    line-height: 100px;
}

.carousel .list .item .introduce .des {
    margin-top: 24px;
    margin-left: 100px;
    font-size: 20px;
    text-align: justify;
    color: rgb(255, 255, 255);
    line-height: 30px;
}

.carousel .list .item:nth-child(1) {
    transform: var(--item1-transform);
    filter: var(--item1-filter);
    z-index: var(--item1-zIndex);
    opacity: var(--item1-opacity);
    pointer-events: none;
}

.carousel .list .item:nth-child(3) {
    transform: var(--item3-transform);
    filter: var(--item3-filter);
    z-index: var(--item3-zIndex);
}

.carousel .list .item:nth-child(4) {
    transform: var(--item4-transform);
    filter: var(--item4-filter);
    z-index: var(--item4-zIndex);
}

/* animation text in item2 */
.carousel .list .item:nth-child(2) .introduce .topic,
.carousel .list .item:nth-child(2) .introduce .des {
    opacity: 0;
    animation: showContent 0.5s 1s ease-in-out 1 forwards;
}

@keyframes showContent {
    from {
        transform: translateY(-30px);
        filter: blur(10px);
    }

    to {
        transform: translateY(0);
        opacity: 1;
        filter: blur(0px);
    }
}

.carousel .list .item:nth-child(2) .introduce .des {
    animation-delay: 0.7s;
}

/* next click */
.carousel.next .item:nth-child(1) {
    animation: transformFromPosition2 0.5s ease-in-out 1 forwards;
}

@keyframes transformFromPosition2 {
    from {
        transform: var(--item2-transform);
        filter: var(--item2-filter);
        opacity: var(--item2-opacity);
    }
}

.carousel.next .item:nth-child(2) {
    animation: transformFromPosition3 0.7s ease-in-out 1 forwards;
}

@keyframes transformFromPosition3 {
    from {
        transform: var(--item3-transform);
        filter: var(--item3-filter);
        opacity: var(--item3-opacity);
    }
}

.carousel.next .item:nth-child(3) {
    animation: transformFromPosition4 0.9s ease-in-out 1 forwards;
}

@keyframes transformFromPosition4 {
    from {
        transform: var(--item4-transform);
        filter: var(--item4-filter);
        opacity: var(--item4-opacity);
    }
}

/* previous */
.carousel.prev .list .item:nth-child(4) {
    animation: transformFromPosition3 0.5s ease-in-out 1 forwards;
}

.carousel.prev .list .item:nth-child(3) {
    animation: transformFromPosition2 0.7s ease-in-out 1 forwards;
}

.carousel.prev .list .item:nth-child(2) {
    animation: transformFromPosition1 0.9s ease-in-out 1 forwards;
}

@keyframes transformFromPosition1 {
    from {
        transform: var(--item1-transform);
        filter: var(--item1-filter);
        opacity: var(--item1-opacity);
    }
}


.arrows {
    position: absolute;
    bottom: 10px;
    width: 1140px;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    left: 50%;
    transform: translateX(-50%);
    visibility: hide;
    opacity: 0;
    pointer-events: none;
}

#prev,
#next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-family: monospace;
    border: 1px solid #5555;
    font-size: large;
    bottom: 20%;
    left: 10%;
}

#next {
    left: unset;
    right: 10%;
}

#back {
    position: absolute;
    z-index: 100;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-bottom: 1px solid #555;
    font-family: Poppins;
    font-weight: bold;
    letter-spacing: 3px;
    background-color: transparent;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.5s;
}

.carousel.showDetail #back {
    opacity: 1;
}

.carousel.showDetail #prev,
.carousel.showDetail #next {
    opacity: 0;
    pointer-events: none;
}




.carousel .more-details-part {
    width: 100%;
    max-width: 1200px;
    height: 150px;
    position: relative;
}

.carousel .more-details-part .more-details {
    position: absolute;
    right: 0;
    width: 230px;
    height: 55px;
    background-color: #ffffff;
    box-shadow: 4px 4px 6px #0d275045,
        -3px -3px 5px #FFFFFF,
        inset -2px -2px 3px #FFFFFF,
        inset 3px 3px 6px #0d275045;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
}

.carousel .more-details-part .more-details .whatsapp {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.carousel .more-details h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
}

.clouds {
    position: absolute;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    bottom: -10px;
    z-index: 1080;


}

.clouds img {
    height: 100px;
}

/* 1240 - 1024 */
/* 1240 - 1024 */
/* 1240 - 1024 */

@media (max-width: 1240px) and (min-width: 1024px) {
    .carousel .list {
        width: 90%;
        min-width: 980px;
        height: 650px;
    }

    /* Price */
    /* Price */
    /* Price */

    .carousel .price {
        width: 80%;
        height: 390px;
        z-index: 1000;
        align-items: center;
        justify-content: end;
        display: flex;
        padding-right: 40px;
    }

    .carousel .price .top-border {
        width: 350px;
        height: 195px;
        border-radius: 13px;
        
    }

    .carousel .price .top-inner {
        width: 345px;
        height: 190px;
        border-radius: 12px;

    }

    .carousel .price .border {
        width: 335px;
        height: 180px;
        border-radius: 10px;

    }

    .carousel .price .border .main {
        width: 330px;
        height: 175px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }

    .carousel .price .main h3 {
        width: 95%;
        font-size: 36px;
        font-weight: 500;
        margin-top: 10px;
        letter-spacing: 1px;
    }

    .carousel .price .main h5 {
        width: 95%;
        font-size: 25px;
        font-weight: 400;
        margin-top: 20px;
        letter-spacing: 0px;
    }

    .carousel .price .main h1 {
        width: 95%;
        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0px;
        margin-top: -30px;


    }

    .carousel .price .main h2 {
        width: 90%;
        width: 85%;
        text-align: left;
        font-size: 18px;
        font-weight: 300;
        color: #ffffff;
        margin-top: -5px;
    }

    /* Price */
    /* Price */
    /* Price */

    .carousel .bg-frame {
        width: 940px;
        height: 650px;
        left: 50%;
        transform: translateX(-50%);
        align-items: end;
        justify-content: start;
        display: flex;
    }

    .carousel .bg-frame img {
        height: 550px;
    }

    /* Stage  */
    /* Stage  */
    /* Stage  */

    .carousel .bg-stage {
        width: 550px;
        height: 120px;
        left: 25px;
        bottom: 0;
    }

    .carousel .bg-stage img {
        height: 100%;
    }

    /* Items */
    /* Items */
    /* Items */


    .carousel .list .item {
        position: absolute;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .carousel .list .item img {
        height: 450px;
        left: 120px;
        top: 360px;
    }

    /* Discriptions */
    /* Discriptions */
    /* Discriptions */


    .carousel .list .item:nth-child(2) .introduce {
        width: 500px;
        top: 40%;
        right: 0;
    }

    .carousel .list .item .introduce .topic {
        font-size: 65px;
        line-height: 80px;
    }

    .carousel .list .item .introduce .des {
        margin-top: 20px;
        margin-left: 70px;
        font-size: 20px;
        font-weight: 300;
    }

    .clouds {
        position: absolute;
        width: 100%;
        height: 100px;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        bottom: -20px;
        z-index: 1080;


    }

    .clouds img {
        height: 100px;
    }

}

/* 1024 - 769 */
/* 1024 - 769 */
/* 1024 - 769 */

@media (max-width: 1024px) and (min-width: 769px) {
    .carousel {
        width: 100%;
        position: relative;
        height: 100vh;
        min-height: 650px;
        max-height: 700px;
    }

       /* Price */
    /* Price */
    /* Price */

    .carousel .price {
        width: 100%;
        height: 680px;
        z-index: 1000;
        align-items: center;
        justify-content: end;
        display: flex;
        padding-right: 40px;
    }

    .carousel .price .top-border {
        width: 310px;
        height: 170px;
        border-radius: 13px;
        
    }

    .carousel .price .top-inner {
        width: 305px;
        height: 165px;
        border-radius: 12px;

    }

    .carousel .price .border {
        width: 295px;
        height: 155px;
        border-radius: 10px;

    }

    .carousel .price .border .main {
        width: 290px;
        height: 150px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }

    .carousel .price .main h3 {
        width: 95%;
        font-size: 32px;
        font-weight: 500;
        margin-top: 10px;
        letter-spacing: 1px;
    }

    .carousel .price .main h5 {
        width: 95%;
        font-size: 24px;
        font-weight: 400;
        margin-top: 10px;
        letter-spacing: 0px;
    }

    .carousel .price .main h1 {
        width: 95%;
        font-size: 35px;
        font-weight: 500;
        letter-spacing: 0px;
        margin-top: -30px;


    }

    .carousel .price .main h2 {
        width: 90%;
        width: 85%;
        text-align: left;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        margin-top: -5px;
    }

    /* Price */
    /* Price */
    /* Price */
    .carousel .list {
        width: 90%;
        min-width: 720px;
        height: 600px;
    }

    .carousel .bg-frame {
        width: 95%;
        min-width: 720px;
        left: 50%;
        transform: translateX(-50%);
        align-items: end;
        justify-content: start;
        display: flex;
    }

    .carousel .bg-frame img {
        height: 500px;
    }

    /* Stage  */
    /* Stage  */
    /* Stage  */

    .carousel .bg-stage {
        width: 550px;
        height: 120px;
        left: 25px;
        bottom: 0;
    }

    .carousel .bg-stage img {
        width: 100%;
        height: 100%;
    }

    /* Items */
    /* Items */
    /* Items */


    .carousel .list .item {
        position: absolute;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .carousel .list .item img {
        height: 400px;
        left: 70px;
        top: 330px;
    }

    /* Discriptions */
    /* Discriptions */
    /* Discriptions */


    .carousel .list .item:nth-child(2) .introduce {
        width: 500px;
        top: 15%;
        right: 0;
    }

    .carousel .list .item .introduce .topic {
        font-size: 56px;
        line-height: 80px;
    }

    .carousel .list .item .introduce .des {
        margin-top: 16px;
        margin-left: 90px;
        font-size: 20px;
        font-weight: 300;
    }

    .clouds {
        position: absolute;
        width: 100%;
        height: 80px;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        bottom: -20px;
        z-index: 1080;
    }

    .clouds img {
        height: 80px;
    }

}


/* Mini Teblates */
/* Mini Teblates */
/* Mini Teblates */

@media (max-width: 769px) and (min-width: 481px) {
    .carousel {
        width: 100%;
        position: relative;
        height: 100vh;
        max-height: 800px;
        min-height: 750px
    }

     /* Price */
    /* Price */
    /* Price */

    .carousel .price {
        width: 100%;
        height: 900px;
        z-index: 1000;
        align-items: center;
        justify-content: end;
        display: flex;
        padding-right: 40px;
    }

    .carousel .price .top-border {
        width: 270px;
        height: 150px;
        border-radius: 13px;
        
    }

    .carousel .price .top-inner {
        width: 265px;
        height: 145px;
        border-radius: 10px;

    }

    .carousel .price .border {
        width: 255px;
        height: 135px;
        border-radius: 8px;

    }

    .carousel .price .border .main {
        width: 250px;
        height: 130px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }

    .carousel .price .main h3 {
        width: 95%;
        font-size: 28px;
        font-weight: 500;
        margin-top: 10px;
        letter-spacing: 1px;
    }

    .carousel .price .main h5 {
        width: 95%;
        font-size: 22px;
        font-weight: 400;
        margin-top: 10px;
        letter-spacing: 0px;
    }

    .carousel .price .main h1 {
        width: 95%;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0px;
        margin-top: -40px;
    }

    .carousel .price .main h2 {
        width: 90%;
        text-align: left;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        margin-top: -5px;
    }

    /* Price */
    /* Price */
    /* Price */

    .carousel .list {
        width: 95%;
        min-width: 450px;
        height: 100vh;
        max-height: 620px;
        min-height: 600px;
    }

    .carousel .bg-frame {
        width: 95%;
        min-width: 450px;
        left: 50%;
        transform: translateX(-50%);
    }

    .carousel .bg-frame img {
        height: 450px;
    }

    /* Stage  */
    /* Stage  */
    /* Stage  */

    .carousel .bg-stage {
        width: 95%;
        height: 140px;
        overflow: hidden;
    }

    .carousel .bg-stage img {
        width: 480px;
        height: 100%;
    }

    /* Items */
    /* Items */
    /* Items */


    .carousel .list .item {
        position: absolute;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .carousel .list .item img {
        height: 350px;
        left: 90px;
        top: 365px;
    }

    /* Discriptions */
    /* Discriptions */
    /* Discriptions */


    .carousel .list .item:nth-child(2) .introduce {
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .carousel .list .item .introduce .topic {
        font-size: 36px;
        line-height: 50px;
        text-align: start;
        margin-left: 20px;
    }

    .carousel .list .item .introduce .des {
        margin: 0;
        margin-left: 40px;
        margin-right: 20px;
        margin-top: 14px;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
    }

    .clouds {
        position: absolute;
        width: 100%;
        height: 70px;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        bottom: -10px;
        z-index: 1080;
    }

    .clouds img {
        height: 70px;
    }

}

/* Mobiles */
/* Mobiles */
/* Mobiles */

@media (max-width: 481px) and (min-width: 260px) {
    .carousel {
        width: 100%;
        position: relative;
        height: 95vh;
        max-height: 775px;
        min-height: 700px;
    }

    /* Price */
    /* Price */
    /* Price */

    .carousel .price {
        width: 95%;
        height: 890px;
        z-index: 1000;
        align-items: center;
        justify-content: end;
        display: flex;
    }

    .carousel .price .top-border {
        width: 211px;
        height: 191px;
        border-radius: 13px;
        
    }

    .carousel .price .top-inner {
        width: 208px;
        height: 188px;
        border-radius: 10px;

    }

    .carousel .price .border {
        width: 203px;
        height: 183px;
        border-radius: 8px;

    }

    .carousel .price .border .main {
        width: 200px;
        height: 180px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 1000;
    }

    .carousel .price .main h3 {
        width: 90%;
        font-size: 20px;
        font-weight: 500;
        margin-top: 10px;
        letter-spacing: 1px;
    }

    .carousel .price .main h5 {
        width: 90%;
        font-size: 18px;
        font-weight: 400;
        margin-top: 8px;
        letter-spacing: 0px;
    }

    .carousel .price .main h1 {
        width: 90%;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0px;
        margin-top: -5px;
    }

    .carousel .price .main h2 {
        width: 90%;
        text-align: left;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        margin-top: 0px;
    }

    /* Price */
    /* Price */
    /* Price */

    .carousel .list {
        width: 85%;
        height: 600px;

    }

    .carousel .bg-frame {
        width: 150%;
        left: 50%;
        transform: translateX(-50%);
    }

    .carousel .bg-frame img {
        height: 450px;
    }

    /* Stage  */
    /* Stage  */
    /* Stage  */

    .carousel .bg-stage {

        width: 100%;
        height: 130px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .carousel .bg-stage img {
        width: 800px;
        height: 130px;
    }

    /* Items */
    /* Items */
    /* Items */


    .carousel .list .item {
        position: absolute;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .carousel .list .item img {
        height: 330px;
        left: -30px;
        top: 370px;
    }

    /* Discriptions */
    /* Discriptions */
    /* Discriptions */


    .carousel .list .item:nth-child(2) .introduce {
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .carousel .list .item .introduce .topic {
        font-size: 26px;
        line-height: 50px;
        text-align: start;
        margin-left: 20px;
    }

    .carousel .list .item .introduce .des {
        margin: 0;
        margin-left: 25px;
        margin-right: 20px;
        margin-top: 14px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }

    .clouds {
        position: absolute;
        width: 100%;
        height: 70px;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        bottom: -10px;
        z-index: 1080;
    }

    .clouds img {
        height: 70px;
    }
}